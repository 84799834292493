
.App {
  margin-top: 45px;
  margin-bottom: 35px;
  /* position: relative; */
  }

.col1 {
  /* border-width: 1px;
  border-style: solid; */
  margin: 2px;
  
}

a,
a label {
  cursor: pointer;
}



body {
  margin: 0;
}

.Introduction {
  /* background: blue; */
  /* height: 30vh; */
}

.About {
  /* background: green; */
  height: 50vh;
  overflow-y: scroll;
}

.Contact {
  /* background: red; */
  /* height: 30vh; */
}

.Projects {
  /* background: red; */
  /* height: 30vh; */
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms, transform 1000ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1000ms, transform 1000ms;
}



  

